<template>
  <div class="absolute top-0 left-0 m-2 p-2 text-black flex z-50 bg-white rounded-lg font-mono text-xs flex flex-col">
    <div class="">
      <span>
        <a
          href="#"
          class="cursor-pointer hover:text-blue-600"
          @click.prevent="showExtendedHiglighted = !showExtendedHiglighted"
        >
          <fa-icon
            v-if="showExtendedHiglighted"
            icon="caret-down"
            class="ml-2"
          />
          <fa-icon
            v-else
            icon="caret-right"
            class="ml-2"
          />
          Highlighting
        </a>

        <template v-if="isHighlightingGroup">
          <span
            class="inline-block px-2 pb-1 bg-gray-700 m-1 rounded-full text-gray-200 cursor-pointer hover:bg-gray-600 hover:text-white"
            @click.prevent="clearHighlight(highlight)"
          >
            <fa-icon
              icon="circle"
              size="sm"
              style="margin: -1px"
              class="text-white"
            />
            {{ highlightedGroup }}
          </span>
        </template> ({{ highlighedCount }})</span>
      <a
        href="#"
        class="ml-4 mr-2 text-gray-800 underline hover:text-blue-500 cursor-pointer"
        @click.prevent="resetViewModifications"
      >Clear</a>
    </div>

    <div
      v-if="showExtendedHiglighted"
      class="max-w-sm"
    >
      <span
        v-for="highlight in viewerHighlighted"
        :key="highlight"
        class="inline-block px-2 pb-1 bg-gray-700 m-1 rounded-full text-gray-200 cursor-pointer hover:bg-gray-600 hover:text-white"
        @click.prevent="clearHighlight(highlight)"
      >
        <fa-icon
          icon="circle"
          size="sm"
          style="margin: -1px"
          class="text-white"
        /> {{ highlight }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ViewerViewModifier',

  props: [
    'highlight',
    'highlightedGroup',
    'viewerHighlighted',
    'isHighlightingGroup'
  ],

  data () {
    return {
      showExtendedHiglighted: true
    }
  },

  computed: {
    highlighedCount () {
      return this.viewerHighlighted.length
    },

    isHighlightingGroup () {
      return (this.highlightedGroup !== null && this.highlightedGroup !== false)
    }
  },

  methods: {
    clearHighlight (highlight) {
      this.$emit('clear-highlight', highlight)
    },

    resetViewModifications () {
      this.$emit('reset')
    }
  }

}
</script>
